import django_session from "@/api/django_session"

const get_my_activity_list = async () => {
    const response = await django_session.get('/erp/activity/my-list/', {
        params: {
            page_size: 0,
        },
    })
    return response.data
}

const get_user_list = async () => {
    const response = await django_session.get('/erp/user/list/', {
        params: {
            page_size: 0,
        },
    })
    return response.data
}

const get_daily_sales = async () => {
    const response = await django_session.get('/erp/customer/daily-sales/')
    return response.data
}

const get_company_sales_to_target = async () => {
    const response = await django_session.get('/erp/customer/company-sales-to-target/')
    return response.data
}

const get_rep_sales_to_target = async () => {
    const response = await django_session.get('/erp/customer/rep-sales-to-target/')
    return response.data
}

const get_service_request_list = async () => {
    const response = await django_session.get('/erp/service/rga/list/')
    return response.data
}

const get_shipping_list = async () => {
    const response = await django_session.get('/erp/service/ship/list/')
    return response.data
}

export { 
    get_my_activity_list,
    get_user_list,
    get_daily_sales,
    get_company_sales_to_target,
    get_rep_sales_to_target,
    get_service_request_list,
    get_shipping_list,
 }


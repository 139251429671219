<template>
  <div class="">
    <div class="d-flex mt-4 flex-wrap align-row-flex">
      <div class="flex-column flex-grow-1 mx-2">
        <div v-if="roles.includes('ADMIN') || roles.includes('OFF_MGR') || roles.includes('SLS_MGR')">
          <b-card
            header-bg-variant="blue"
            hide-footer
            class=""
            no-body>
            <template #header>
              <span class="text-white">Company Sales to Target</span>
            </template>
            <template>
              <div v-if="companySalesTargetError">
                <b-alert show variant="danger" class="p-2 m-2">
                  <p>Error getting sales targets</p>
                </b-alert>
              </div>
              <m-spinny-message
                v-if="gettingCompanySalesTargetData"
                :message="'Getting Sales Targets...'"
                class="p-5" />
              <table
                v-if="!gettingCompanySalesTargetData"
                class="table table-bordered">
                <thead>
                  <tr>
                    <th>Supplier</th>
                    <th><div class="float-right">Target</div></th>
                    <th><div class="float-right">Actual</div></th>
                    <th><div class="float-right">% of Target</div></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in companySalesTargets.detail"
                    :key="index">
                    <td>{{ item.supplier_name }}</td>
                    <td>
                      <div class="float-right">$ {{ item.sales_target_total_dollars }}</div>
                    </td>
                    <td>
                      <div class="float-right">$ {{ item.current_year_sales_actual_formatted }}</div>
                    </td>
                    <td>
                      <div class="float-right">{{ item.sales_to_target_percent.toFixed(1) }} %</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
          </b-card>
        </div>
        <div v-if="roles.includes('SLS_REP')">
          <b-card
            header-bg-variant="blue"
            hide-footer
            class=""
            no-body>
            <template #header>
              <span class="text-white">Rep Sales to Target</span>
            </template>
            <template>
              <m-spinny-message
                v-if="gettingRepSalesTargetData"
                :message="'Getting Sales Targets...'"
                class="p-5" />
              <div v-if="repSalesTargetError">
                <b-alert show variant="danger" class="p-2 m-2">
                  <p>Error getting sales targets</p>
                </b-alert>
              </div>
              <table
                v-if="!gettingRepSalesTargetData"
                class="table table-bordered">
                <thead>
                  <tr>
                    <th>Supplier</th>
                    <th><div class="float-right">Target</div></th>
                    <th><div class="float-right">Actual</div></th>
                    <th><div class="float-right">% of Target</div></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in repSalesTargets.detail[0].supplier_data"
                    :key="index">
                    <td>{{ item.supplier_name }}</td>
                    <td>
                      <div class="float-right">$ {{ item.sales_target_total_dollars.toFixed(2) }}</div>
                    </td>
                    <td>
                      <div class="float-right">$ {{ item.current_year_sales_actual.toFixed(2) }}</div>
                    </td>
                    <td>
                      <div class="float-right">{{ item.sales_to_target_percent.toFixed(1) }} %</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
          </b-card>
        </div>
        <div v-if="roles.includes('CUST_SRV') || roles.includes('WAREHOUSE')">
          <b-card
            header-bg-variant="indigo"
            hide-footer
            class=""
            no-body>
            <template #header>
              <span class="text-white">Shipping Requests</span>
            </template>
            <template>
              <m-spinny-message
                v-if="gettingShippingData"
                :message="'Getting Shipping Requests'"
                class="p-5" />
              <table
                v-if="!gettingShippingData"
                class="table table-bordered">
                <thead>
                  <tr>
                    <th>SR#</th>
                    <th><div class="float-right">Shipper</div></th>
                    <th><div class="float-right">Ship To</div></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in lastFiveShippingRequests"
                    :key="index">
                    <td>{{ item.service_request_number }}</td>
                    <td>
                      <div class="float-right">{{ item.shipper_name }}</div>
                    </td>
                    <td>
                      <div class="float-right">{{ item.contact_name }}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
          </b-card>
        </div>
      </div>
      <div class="flex-column flex-grow-1 mx-2">
        <div
          v-if="
            roles.includes('ADMIN') ||
            roles.includes('OFF_MGR') ||
            roles.includes('SLS_MGR') ||
            roles.includes('SLS_REP')
          ">
          <b-card
            header-bg-variant="pink"
            hide-footer
            class=""
            no-body>
            <template #header>
              <span
                class="text-white"
                >Daily Sales</span
              >
            </template>
            <template>
              <div v-if="dailySalesError">
                <b-alert show variant="danger" class="p-2 m-2">
                  <p>Error getting sales data</p>
                </b-alert>
              </div>
              <m-spinny-message
                v-if="gettingSalesData"
                :message="'Getting Sales Data...'"
                class="p-5" />
              <table
                v-if="!gettingSalesData"
                class="table table-bordered">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th><div class="float-right">Sales</div></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in dailySales"
                    :key="index">
                    <td>{{ item.date }}</td>
                    <td>
                      <div class="float-right">{{ item.total_sales }}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
          </b-card>
        </div>
        <div v-if="roles.includes('CUST_SRV') || roles.includes('WAREHOUSE')">
          <b-card
            header-bg-variant="orange"
            hide-footer
            class=""
            no-body>
            <template #header>
              <span class="text-white">Service Requests</span>
            </template>
            <template>
              <m-spinny-message
                v-if="gettingServiceRequestData"
                :message="'Getting Service Requests'"
                class="p-5" />
              <table
                v-if="!gettingServiceRequestData"
                class="table table-bordered">
                <thead>
                  <tr>
                    <th>SR#</th>
                    <th><div class="float-right">Type</div></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in lastFiveServiceRequests"
                    :key="index">
                    <td>{{ item.rga_number }}</td>
                    <td>
                      <div class="float-right">{{ item.service_request_type_name }}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
          </b-card>
        </div>
      </div>

      <div class="flex-column flex-grow mx-2">
        <b-card
          header-bg-variant="info"
          hide-footer
          class="full-height"
          no-body>
          <template #header>
            <span class="text-white">My Activities</span>
            <span class="float-right">
              <b-form-group
                id="hide-closed-activity-fieldset"
                label="Hide Closed"
                label-for="hide-closed-activity-yn"
                v-slot="{ ariaDescribedby }"
                class="field-label-form-group d-inline text-white">
                <b-form-checkbox
                  id="hide-closed-activity-yn"
                  :aria-describedby="ariaDescribedby"
                  inline
                  v-model="hideClosedActivityYN"></b-form-checkbox>
              </b-form-group>
            </span>
          </template>
          <b-table
            id="my-activity-table"
            ref="__myActivityTable"
            head-variant="dark"
            responsive
            hover
            :busy="activityIsSaving"
            :items="filteredActivityList"
            :fields="activity_list_fields"
            :current-page="current_page"
            :per-page="0">
            <template #cell(actions)="row">
              <i
                class="fas fa-pencil text-primary d-inline icon-pointer"
                @click="goActivityDetail(row.item, row.index)" />
              <i
                v-if="row.item.is_overdue_yn"
                class="fas fa-bell text-red ml-1 d-inline" />
            </template>
            <template #cell(due_date)="row">
              {{ new Date(row.item.due_date).toLocaleDateString() }}
            </template>
          </b-table>
        </b-card>
      </div>
    </div>

    <!--  -->
    <!-- Activity Modal -->
    <!--  -->
    <div id="activity-modal-div">
      <b-modal
        id="activity-modal"
        v-model="showActivityModal"
        title="Activity Detail"
        centered
        scrollable
        no-close-on-esc
        no-close-on-backdrop
        hide-header-close
        header-bg-variant="info"
        header-text-variant="white"
        size="xl">
        <b-row>
          <div class="col-md-6 col-sm-12">
            <b-alert
              v-model="showActivityAlert"
              dismissible
              :variant="activityAlertVariant"
              >{{ activityAlertMessage }}</b-alert
            >
            <b-form-row>
              <b-colxx
                md="3"
                sm="12">
                <validation-provider
                  rules="required"
                  name="Status"
                  v-bind="$attrs"
                  v-slot="{ errors }">
                  <label class="field-label-form-group mt-2">Status *</label>
                  <b-form-select
                    v-model="activityDetail.status"
                    :options="activityStatusList"
                    label-field="'Assign Activity To'"
                    :required="true"
                    class="custom-input-select form-control" />
                  <div
                    v-if="errors[0]"
                    class="invalid-feedback text-xs"
                    style="display: inline-block">
                    {{ errors[0] }}
                  </div>
                </validation-provider>
              </b-colxx>
              <b-colxx
                md="9"
                sm="12">
                <m-text-input
                  id="subject"
                  label="Subject"
                  :value="activityDetail.subject"
                  :alternative="false"
                  :readonly="false"
                  :group="false"
                  name="Subject"
                  @update-value="
                    (input) => {
                      activityDetail.subject = input
                    }
                  " />
              </b-colxx>
              <b-colxx
                md="4"
                sm="12">
                <m-text-input
                  id="reminder-date"
                  label="Reminder Date"
                  :value="activityDetail.reminder_date"
                  :alternative="false"
                  :rules="{ required: false }"
                  type="datetime-local"
                  :group="false"
                  name="Reminder Date"
                  @update-value="
                    (input) => {
                      activityDetail.reminder_date = input
                    }
                  " />
              </b-colxx>

              <b-colxx
                md="4"
                sm="12">
                <m-text-input
                  id="due-date"
                  label="Due Date"
                  :value="activityDetail.due_date"
                  :alternative="false"
                  :rules="{ required: true }"
                  type="datetime-local"
                  :group="false"
                  name="Due Date"
                  @update-value="
                    (input) => {
                      activityDetail.due_date = input
                    }
                  " />
              </b-colxx>
              <b-colxx
                md="4"
                sm="12">
                <validation-provider
                  rules="required"
                  name="Owner"
                  v-bind="$attrs"
                  v-slot="{ errors }">
                  <label class="field-label-form-group mt-2">Owner *</label>
                  <b-form-select
                    v-model="activityDetail.assigned_to"
                    :options="userList"
                    label-field="'Owner'"
                    :required="true"
                    value-field="user_id"
                    text-field="full_name"
                    class="custom-input-select form-control" />
                  <div
                    v-if="errors[0]"
                    class="invalid-feedback text-xs"
                    style="display: inline-block">
                    {{ errors[0] }}
                  </div>
                </validation-provider>
              </b-colxx>
              <b-colxx
                v-if="hasOutlookTask"
                md="12"
                sm="12">
                <b-form-text class="text-uppercase text-xs text-purple">
                  This activity has an associated task in Outlook. Changes in this activity will be reflected in your
                  Outlook task when you save.
                </b-form-text>
              </b-colxx>
              <b-colxx
                md="12"
                sm="12">
                <m-text-area
                  id="note"
                  :value="activityDetail.detail"
                  name="Task Notes"
                  :rows="7"
                  :rules="{ required: false, max: 4000 }"
                  label="Notes"
                  :alternative="false"
                  :group="false"
                  @update-value="
                    (input) => {
                      activityDetail.detail = input
                    }
                  " />
              </b-colxx>
            </b-form-row>

            <m-tracking-info
              :created-by-name="activityDetail.created_by_name"
              :created-at="activityDetail.created_at"
              :modified-by-name="activityDetail.modified_by_name"
              :modified-at="activityDetail.modified_at" />
          </div>
          <div class="col-md-6 col-sm-12">
            <div class="card card-header bg-primary text-white">
              {{ relatedEntityName }}
            </div>
            <div v-if="isShipmentType">
              <b-form-row>
                <b-colxx
                  md="12"
                  sm="12">
                  <m-text-input
                    id="ship-type-name"
                    label="Shipment Type"
                    :value="activityDetail.related_entity.shipment_type"
                    :alternative="true"
                    :readonly="true"
                    :rules="{ required: false }"
                    type="text"
                    :group="false"
                    name="Shipment Type" />
                </b-colxx>
                <b-colxx
                  md="12"
                  sm="12">
                  <m-text-input
                    id="ship-status"
                    label="Status"
                    :value="activityDetail.related_entity.shipment_status"
                    :alternative="true"
                    :readonly="true"
                    :rules="{ required: false }"
                    type="text"
                    :group="false"
                    name="Shipment Type" />
                </b-colxx>
                <b-colxx
                  md="12"
                  sm="12">
                  <m-text-input
                    id="shipper"
                    label="Shipper"
                    :value="activityDetail.related_entity.shipper"
                    :alternative="true"
                    :readonly="true"
                    :rules="{ required: false }"
                    type="text"
                    :group="false"
                    name="Shipper" />
                </b-colxx>
                <b-colxx
                  md="12"
                  sm="12">
                  <m-text-input
                    id="est-delivery-date"
                    label="Est. Delivery Date"
                    :value="activityDetail.related_entity.estimated_delivery_date"
                    :alternative="true"
                    :readonly="true"
                    :rules="{ required: false }"
                    type="text"
                    :group="false"
                    name="Est. Delivery Date" />
                </b-colxx>
                <b-colxx
                  md="12"
                  sm="12">
                  <m-text-input
                    id="customer"
                    label="Customer"
                    :value="activityDetail.related_entity.customer_name"
                    :alternative="true"
                    :readonly="true"
                    :rules="{ required: false }"
                    type="text"
                    :group="false"
                    name="Customer" />
                </b-colxx>
              </b-form-row>
            </div>
            <div v-if="isSalesCallType">
              <b-form-row>
                <b-colxx
                  md="12"
                  sm="12">
                  <m-text-input
                    id="contact-name"
                    label="Contact Name"
                    :value="activityDetail.related_entity.contact"
                    :alternative="true"
                    :readonly="true"
                    :rules="{ required: false }"
                    type="text"
                    :group="false"
                    name="Contact Name"
                    @update-value="
                      (input) => {
                        activityDetail.related_entity.contact = input
                      }
                    " />
                </b-colxx>
                <b-colxx
                  md="12"
                  sm="12">
                  <m-text-input
                    id="customer-name"
                    label="Customer"
                    :value="activityDetail.related_entity.customer_name"
                    :alternative="true"
                    :readonly="true"
                    :rules="{ required: false }"
                    type="text"
                    :group="false"
                    name="Customer"
                    @update-value="
                      (input) => {
                        activityDetail.related_entity.customer_name = input
                      }
                    " />
                </b-colxx>
                <b-colxx
                  md="12"
                  sm="12">
                  <m-text-input
                    id="category-name"
                    label="Category Name"
                    :value="activityDetail.related_entity.sales_call_category"
                    :alternative="true"
                    :readonly="true"
                    :rules="{ required: false }"
                    type="text"
                    :group="false"
                    name="Caetgory"
                    @update-value="
                      (input) => {
                        activityDetail.related_entity.sales_call_category = input
                      }
                    " />
                </b-colxx>
                <b-colxx
                  md="12"
                  sm="12">
                  <m-text-input
                    id="call-date"
                    label="Call Date"
                    :value="new Date(activityDetail.related_entity.call_date).toLocaleString()"
                    :alternative="true"
                    :readonly="true"
                    :rules="{ required: false }"
                    type="text"
                    :group="false"
                    name="Call Date"
                    @update-value="
                      (input) => {
                        activityDetail.related_entity.call_date = input
                      }
                    " />
                </b-colxx>
                <b-colxx
                  md="12"
                  sm="12">
                  <m-text-area
                    id="note"
                    :value="activityDetail.related_entity.notes"
                    name="Call Notes"
                    :rows="7"
                    :rules="{ required: false }"
                    label="Notes"
                    :alternative="false"
                    :readonly="true"
                    :group="false"
                    @update-value="
                      (input) => {
                        activityDetail.related_entity.notes = input
                      }
                    " />
                </b-colxx>
              </b-form-row>
            </div>
          </div>
        </b-row>
        <template #modal-footer="{}">
          <b-button
            v-if="!activitySaveComplete"
            variant="success"
            :disabled="activityIsSaving"
            @click="activitySave()"
            >{{ activitySaveButtonLabel }}</b-button
          >
          <b-button
            variant="warning"
            :disabled="activityIsSaving"
            @click="closeActivity()"
            >Close</b-button
          >
        </template>
      </b-modal>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from "vuex"
  import django_session from "@/api/django_session"

  import { activityStatusList } from "@/constants/objects/activity"
  import { saveActivity } from "@/functions/activity"

  import { 
    get_my_activity_list,
    get_user_list,
    get_daily_sales,
    get_company_sales_to_target,
    get_rep_sales_to_target,
    get_service_request_list,
    get_shipping_list,
  } from "@/views/dashboard/dashboard_methods"

  export default {
    name: "Home",
    components: {},

    data() {
      return {
        activityStatusList,
        myActivityList: [],
        activity_list_fields: [
          {
            label: "",
            key: "actions",
          },
          {
            label: "status",
            key: "status",
          },
          {
            label: "Subject",
            key: "extended_subject",
          },
          {
            label: "Due Date",
            key: "due_date",
          },
        ],

        current_page: 1,
        activityEntityType: "",
        showActivityModal: false,

        activityDetail: {},
        activityIndex: null,
        activityIsSaving: false,
        hideClosedActivityYN: true,

        activitySaveComplete: false,

        showActivityAlert: false,
        activityAlertMessage: "",
        activityAlertVariant: "",

        userList: [],
        dailySales: [],
        gettingSalesData: false,
        dailySalesError: false,

        companySalesTargets: [],
        gettingCompanySalesTargetData: false,
        companySalesTargetError: false,
        
        repSalesTargets: {
          detail: [
            {
              sales_rep_name: "",
              sales_target_total_dollars: 0,
              current_year_sales_actual: 0,
              sales_to_target_percent: 0,
              supplier_data: [
                {
                  supplier_name: "",
                  sales_target_total_dollars: 0,
                  current_year_sales_actual: 0,
                  sales_to_target_percent: 0,
                },
              ],
            },
          ],
        },
        gettingRepSalesTargetData: false,
        repSalesTargetError: false,

        serviceRequestList: [],
        shippingList: [],
        gettingServiceRequestData: false,
        gettingShippingData: false,
      }
    },

    computed: {
      ...mapGetters("auth", ["user", "roles", "permissions"]),

      relatedEntityName() {
        if (this.showActivityModal) {
          return this.activityDetail.related_entity.entity_type_name
        }
        return "Related Entity"
      },

      isSalesCallType() {
        if (this.showActivityModal) {
          if (this.activityDetail.entity_type_class_name === "salescall") {
            return true
          }
        }
        return false
      },

      isShipmentType() {
        if (this.showActivityModal) {
          if (this.activityDetail.entity_type_class_name === "shipment") {
            return true
          }
        }
        return false
      },

      hasOutlookTask() {
        if (this.showActivityModal) {
          if (this.activityDetail.related_outlook_task_id !== "") {
            return true
          }
        }
        return false
      },

      activitySaveButtonLabel() {
        if (this.activityIsSaving) {
          return "Saving..."
        } else {
          return "Save"
        }
      },

      filteredActivityList() {
        let list = this.myActivityList
        if (this.hideClosedActivityYN) {
          let filteredList = list.filter((x) => x.status !== "Closed")
          return filteredList
        }
        return list
      },

      lastFiveServiceRequests() {
        let list = this.serviceRequestList
        let filteredList = list.slice(0, 5)
        return filteredList
      },

      lastFiveShippingRequests() {
        let list = this.shippingList
        let filteredList = list.slice(0, 5)
        return filteredList
      },
    },

    created() {},

    async mounted() {
      document.title = "GEM Home"
      this.gettingSalesData = true
      this.gettingCompanySalesTargetData = true
      this.gettingRepSalesTargetData = true
      this.gettingServiceRequestData = true
      this.gettingShippingData = true
      this.buildDashboardData()
    },

    methods: {
      async goActivityDetail(item) {
        this.activityIndex = this.myActivityList.findIndex((x) => x.activity_id === item.activity_id)
        this.activityEntityType = item.entity_type_class_name
        await django_session.get("erp/activity/" + item.activity_id + "/").then((response) => {
          console.log(response.data)
          this.activityDetail = response.data
          var tzoffset = new Date().getTimezoneOffset() * 60000 //offset in milliseconds
          var dueDate = new Date(new Date(this.activityDetail.due_date) - tzoffset).toISOString().replace(/:[^:]*$/, "")
          var reminderDate = new Date(new Date(this.activityDetail.reminder_date) - tzoffset)
            .toISOString()
            .replace(/:[^:]*$/, "")

          this.activityDetail.due_date = dueDate
          this.activityDetail.reminder_date = reminderDate
          this.showActivityModal = true
        })
      },

      async buildDashboardData() {
        try {
          // Base promises that always run
          const basePromises = [
            get_my_activity_list(),
            get_user_list()
          ]

          // Role-specific promises
          if (this.roles.includes("ADMIN") || this.roles.includes("OFF_MGR") || this.roles.includes("SLS_MGR")) {
            basePromises.push(get_daily_sales())
            basePromises.push(get_company_sales_to_target())
          }

          if (this.roles.includes("SLS_REP")) {
            basePromises.push(get_rep_sales_to_target())
            basePromises.push(get_daily_sales())
          }

          if (this.roles.includes("CUST_SRV") || this.roles.includes("WAREHOUSE")) {
            basePromises.push(get_service_request_list())
            basePromises.push(get_shipping_list())
          }

          // Run all promises concurrently
          const results = await Promise.all(basePromises)

          // Assign results to data properties
          this.myActivityList = results[0]
          this.userList = results[1]

          let resultIndex = 2

          if (this.roles.includes("ADMIN") || this.roles.includes("OFF_MGR") || this.roles.includes("SLS_MGR")) {
            this.dailySales = results[resultIndex++]
            this.companySalesTargets = results[resultIndex++]
            this.gettingSalesData = false
            this.gettingCompanySalesTargetData = false
          }

          if (this.roles.includes("SLS_REP")) {
            this.repSalesTargets = results[resultIndex++]
            this.dailySales = results[resultIndex++]
            this.gettingRepSalesTargetData = false
            this.gettingSalesData = false
          }

          if (this.roles.includes("CUST_SRV") || this.roles.includes("WAREHOUSE")) {
            this.serviceRequestList = results[resultIndex++]
            this.shippingList = results[resultIndex++]
            this.gettingServiceRequestData = false
            this.gettingShippingData = false
          }

        } catch (error) {
          console.error("Error building dashboard data:", error)
          // Handle errors appropriately - could set error flags here
          if (error.response) {
            if (error.response.status === 404) {
              this.dailySalesError = true
              this.companySalesTargetError = true 
              this.repSalesTargetError = true
            }
          }
        } finally {
          // Ensure loading states are reset even if there's an error
          this.gettingSalesData = false
          this.gettingCompanySalesTargetData = false
          this.gettingRepSalesTargetData = false
          this.gettingServiceRequestData = false
          this.gettingShippingData = false
        }
      },

      closeActivity() {
        this.showActivityModal = false
        this.activityDetail = {}
        this.activityIndex = null
        this.activityEntityType = ""
        this.activityAlertMessage = ""
        this.activityAlertVariant = ""
        this.showActivityAlert = false
        this.activitySaveComplete = false
      },

      async activitySave() {
        this.activityIsSaving = true
        let saveActivityRecord = await saveActivity(this.activityDetail, true)
        console.log(saveActivityRecord)

        if (saveActivityRecord.status === 200) {
          console.log("Activity save success")
          this.activityAlertMessage = "Activity has been updated."
          this.activityAlertVariant = "success"
          this.showActivityAlert = true
          this.myActivityList[this.activityIndex].assigned_to = saveActivityRecord.data.assigned_to
          this.myActivityList[this.activityIndex].assigned_to_name = saveActivityRecord.data.assigned_to_name
          this.myActivityList[this.activityIndex].detail = saveActivityRecord.data.detail
          this.myActivityList[this.activityIndex].due_date = saveActivityRecord.data.due_date
          this.myActivityList[this.activityIndex].modified_at = saveActivityRecord.data.modified_at
          this.myActivityList[this.activityIndex].modified_by = saveActivityRecord.data.modified_by
          this.myActivityList[this.activityIndex].modified_by_name = saveActivityRecord.data.modified_by_name
          this.myActivityList[this.activityIndex].reminder_date = saveActivityRecord.data.reminder_date
          this.myActivityList[this.activityIndex].status = saveActivityRecord.data.status
          this.myActivityList[this.activityIndex].subject = saveActivityRecord.data.subject
        } else {
          console.log("Activity save error")
          let errorMessage = error.response.data
          this.activityAlertMessage = "Error saving activity " + errorMessage
          this.activityAlertVariant = "warning"
          this.showActivityAlert = true
          this.activityIsSaving = false
        }

        this.activityIsSaving = false
        this.activitySaveComplete = true
      },
    },
  }
</script>
<style scoped>
  .full-height {
    height: calc(100vh - 450px);
    overflow-y: auto;
  }

  .table th {
    font-weight: 600;
    font-size: 0.8rem;
  }
  .table td,
  th {
    padding: 0.5rem;
  }
</style>
